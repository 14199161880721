import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ContentDetailPage from '@/views/page/ContentDetailPage.vue'

// tracking
import tracker from '@/helpers/tracking'
import { store } from '@/store'
import { createFromParameter, createToParameter, getCardId } from '@/store/modules/StatisticsLog'
import { sessionCheck } from '@/domain/session/sessionCheck'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/pid/:pid(\\d+)/:bundleIndex(\\d+)?/:contentIndex(\\d+)?',
    name: 'ContentDetail',
    component: ContentDetailPage,
    meta: {
      isKn: true,
      pageTitle: { en: 'Item detail', ja: 'アイテム詳細画面' },
      description: { en: '', ja: '' },
      ogType: 'article',
    },
  },
  {
    path: '/info\\:ndljp/pid/:pid(\\d+)/:contentIndex(\\d+)?',
    redirect: (to) => {
      const contentIndex = to.params.contentIndex ? to.params.contentIndex : '1'
      return `/pid/${to.params.pid}/1/${contentIndex}`
    },
  },
  {
    path: '/:static(.*).html',
    redirect: (to) => {
      return to.fullPath.replace('.html', '')
    },
  },
  // routeの追加はこれより上に行う
  {
    path: '/:static(.*)',
    name: 'StaticPage',
    component: () => import('../views/page/Static.vue' /* webpackChunkName: "static" */),
    meta: {
      pageTitle: { en: 'Historical Recordings Collection', ja: '東日本大震災アーカイブ' },
      description: { en: '(description)', ja: '(description)' },
      ogType: 'article',
    },
  },
]

const setI18nAlias = (routes: Array<RouteRecordRaw>): Array<RouteRecordRaw> => {
  return routes.map(route => {
    let children
    if (route.children && route.children.length > 0) {
      children = setI18nAlias(route.children)
    }
    return {
      ...route,
      children,
      alias: ['/ja' + route.path, '/en' + route.path],
    }
  })
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: setI18nAlias(routes),
  scrollBehavior: async (to, from, savedPosition) => {
    const findElement = async (hash: string, times = 0): Promise<Element | undefined> => {
      if (!document) return // サーバーサイド実行時
      if (!hash || hash === '#') return // アンカーリンクがない時
      if (times > 20) return // 20回のリトライで対象要素が見つからない時
      if (times === 0) { // 初回画面遷移時
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(findElement(hash, ++times))
          }, 1500) // 画面が描画され終わるまで待機する
        })
      }

      return document.querySelector(hash) ||
          new Promise((resolve) => {
            setTimeout(() => {
              resolve(findElement(hash, ++times))
            }, 100)
          })
    }

    // findElementは初回1500ms待機するが、ページ内遷移では初回待機させないため
    const isSamePageTransition = !from.name ? false : from.name === to.name
    const targetElement = (await findElement(to.hash, Number(isSamePageTransition)) as HTMLElement|undefined)

    if (savedPosition) {
      if (isSamePageTransition && to.hash === '') {
        // ページトップへ戻る(<a href='#'>~</a>)をクリックした場合
        return { top: 0 }
      } else {
        return savedPosition
      }
    } else if (!targetElement) {
      return { left: 0, top: 0 }
    } else if ('scrollBehavior' in document.documentElement.style) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      return { el: to.hash }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  const toTop = () => {
    // セッション切れログインモーダルを表示する間、一時的にトップ画面を表示する。
    next('/')
  }
  // セッションチェックをする
  if (!await sessionCheck(to.fullPath, toTop)) return

  const isJapanesePath = (path: string) => path.startsWith('/ja/') || path === '/ja'
  const isEnglishPath = (path: string) => path.startsWith('/en/') || path === '/en'
  // ページごとにメタを設定する
  if (isJapanesePath(to.fullPath)) to.meta.lang = 'ja'
  if (isEnglishPath(to.fullPath)) to.meta.lang = 'en'
  // 必要に応じてURLを書き換えて遷移する
  if (isJapanesePath(from.fullPath) && !isJapanesePath(to.fullPath)) {
    next(isEnglishPath(to.fullPath) ? to.fullPath.replace(/^\/en/, '/ja') : '/ja' + to.fullPath)
    return
  }
  if (isEnglishPath(from.fullPath) && !isEnglishPath(to.fullPath)) {
    next(isJapanesePath(to.fullPath) ? to.fullPath.replace(/^\/ja/, '/en') : '/en' + to.fullPath)
    return
  }
  next()
})

// 引数の確認のため、未使用仮引数をあえて記載しています。
// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach((to, from, failure) => {
  const cardId = getCardId(store)
  const toMeta = createToParameter(to, cardId)
  const fromMeta = createFromParameter(from, cardId)
  tracker.push({ key: 'router', to: toMeta, from: fromMeta })
  if (cardId != null) {
    tracker.push({ key: 'router', to: toMeta, from: fromMeta, cardId: cardId })
  }
})

export default router
